import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectedField",
    "criteriaNumericFields",
    "criteriaSelectFields",
    "criteriaBooleanFields",
    "criteriaTextFields",
    "selectComparisonInput",
    "selectComparisonOutput",
    "units"
  ]
  static values = { fieldOptions: Object, criteriaOptions: Object }

  connect() {
    this.criteriaChanged()
    this.comparisonTriggerChanged()
  }

  criteriaChanged() {
    let selectedValue = this.selectedFieldTarget.value
    let fieldTypeToShow = this.fieldOptionsValue[selectedValue]

    this.updateShownFields(fieldTypeToShow)
    this.updateCriteriaSelectOptions(fieldTypeToShow, selectedValue)
    this.updateUnits(selectedValue)
  }

  updateShownFields(fieldTypeToShow) {
    switch(fieldTypeToShow) {
      case 'number_field':
        this.criteriaNumericFieldsTarget.removeAttribute("hidden")
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'select_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.removeAttribute("hidden")
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'boolean_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.removeAttribute("hidden")
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
        break;
      case 'text_field':
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.removeAttribute("hidden")
        break;
      default:
        this.criteriaNumericFieldsTarget.setAttribute("hidden", true)
        this.criteriaSelectFieldsTarget.setAttribute("hidden", true)
        this.criteriaBooleanFieldsTarget.setAttribute("hidden", true)
        this.criteriaTextFieldsTarget.setAttribute("hidden", true)
    }
  }

  updateCriteriaSelectOptions(fieldTypeToShow, selectedValue) {
    let selectOptions = []
    let target = this.selectComparisonOutputTarget

    if (fieldTypeToShow != 'select_field') {
      var length = target.options.length
      var i
      for (i = length-1; i >= 0; i--) {
        target.options[i] = null
      }
    } else {
      selectOptions = this.criteriaOptionsValue[selectedValue]
      target.innerHTML = ""
      selectOptions.forEach((o) => {
        var opt = document.createElement('option')
        opt.value = o[1]
        opt.innerHTML = o[0]
        target.appendChild(opt)
      })
    }
  }

  comparisonTriggerChanged() {
    let selectedValue = this.selectComparisonInputTarget.value

    switch(selectedValue) {
      case 'is_one_of':
        this.selectComparisonOutputTarget.setAttribute("multiple", true)
        break;
      default:
        this.selectComparisonOutputTarget.removeAttribute("multiple")
    }
  }

  updateUnits(selectedValue) {
    let yearsIf = ["age_difference", "tenure", "experience"]

    if (!yearsIf.includes(selectedValue)) {
      this.unitsTarget.textContent = ""
    } else if (yearsIf.includes(selectedValue)) {
      this.unitsTarget.textContent = "years"
    }
  }
}

