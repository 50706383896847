import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "conditionInput", "conditionOutput" ]
  static values = { showFor: Array }

  connect() {
    this.triggerChanged()
    this.checkboxChanged()
  }

  triggerChanged() {
    if(!this.showForValue.includes(this.conditionInputTarget.value)) {
      this.conditionOutputTarget.setAttribute("hidden", true)
    } else {
      this.conditionOutputTarget.removeAttribute("hidden")
    }
  }

  checkboxChanged() {
    if(!this.showForValue[0] == this.conditionInputTarget.checked) {
      this.conditionOutputTarget.setAttribute("hidden", true)
    } else {
      this.conditionOutputTarget.removeAttribute("hidden")
    }
  }
}
